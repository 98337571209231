export const About = () => (
    <section className="container s home-about">
        <h2>ABOUT</h2>
        <div className="white-blur-bg">
            <div className="about-description">
                <b>Ganga Pay</b>     develops software specialising in telecommunications and payment technologies. We centre our work around developing, maintaining, and enhancing software applications and systems that enable efficient communication, data exchange, easy payment processing, and fintech solutions.
            </div>
            <div className="about-description">
                <b>Based</b> in the United Kingdom, Ganga Pay integrates and tests new tech solutions at the forefront. Our team has extensive expertise and is ready to develop the most suitable solution for clients' needs.
            </div>
        </div>
    </section>
)