import coinIcon from '../../assets/coin.svg';
import messageCircleCheckIcon from '../../assets/message-circle-check.svg';
import rectanglesMixedIcon from '../../assets/rectangles-mixed.svg';

export const StrongPoints = () => (
    <section className="container s strong-points">
        <h2>Our strong points</h2>
        <div className="strong-points-list">
            <div className="strong-point white-blur-bg">
                <h3>Simple API <br/> Integration</h3>
                <p>Connect our services quickly without the need to understand underlying technical details</p>
                <div className="icon-wrapper">
                    <img src={coinIcon} alt="coin-icon"/>
                </div>
            </div>
            <div className="strong-point white-blur-bg">
                <h3>Round-clock <br/> client support</h3>
                <p>We provide 24/7 technical support with the personal manager assistance</p>
                <div className="icon-wrapper">
                    <img src={messageCircleCheckIcon} alt="message-circle-check-icon"/>
                </div>
            </div>
            <div className="strong-point white-blur-bg">
                <h3>Analytics <br/> dashboards</h3>
                <p>Control data and KPI in dashboards to get a real-time overview of business metrics</p>
                <div className="icon-wrapper">
                    <img src={rectanglesMixedIcon} alt="rectangles-mixed-icon"/>
                </div>
            </div>
        </div>
    </section>
)