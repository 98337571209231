import {Header} from "./header";
import {Footer} from "./footer";

export const Page = ({ homePage = false, notFound = false, services = false, contactUs = false, children }) => (
    <>
        <Header />
        <main>
            {children}
        </main>
        <Footer/>
        {(homePage || notFound) && (
            <div className="bg big-molecula"/>
        )}
        {homePage && (
            <div className="bg small-molecula"/>
        )}
        {services && (
            <>
                <div className="bg ball ball-s"/>
                <div className="bg ball ball-m"/>
                <div className="bg ball ball-l"/>
                <div className="bg ball ball-xl"/>
                <div className="bg service-molecula"/>
            </>
        )}
        {contactUs && (
            <>
                <div className="bg molecula"/>
            </>
        )}
    </>
)
