import {useEffect, useState} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import logo from '../assets/logo.svg';
import hamburger from '../assets/hamburger.svg';
import close from '../assets/close.svg';

export const Header = () => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [isMenuOpened, setMenuOpened] = useState(false);

    useEffect(() => {
        if(isMenuOpened){
            document.body.style.overflowY = 'hidden';
        }
        if(!isMenuOpened ){
            document.body.style.overflowY = 'unset';
        }
    }, [isMenuOpened]);

    return (
        <header className={isMenuOpened ? 'opened' : ''}>
            <div className="container">
                <div className="header-inner">
                    <div className="logo-wrapper">
                        <img className='logo' src={logo} alt="logo" onClick={() => navigate('/')}/>
                        <div className="additional-buttons">
                            <img className='hamburger' src={isMenuOpened ? close : hamburger} alt="hamburger" onClick={() => setMenuOpened(!isMenuOpened)}/>
                        </div>
                    </div>
                    <nav>
                        <Link to={'/'} data-active={pathname === '/'} onClick={() => setMenuOpened(false)}>Home</Link>
                        <Link to={'/our-services'} data-active={pathname === '/our-services'} onClick={() => setMenuOpened(false)}>Our Services</Link>
                        <Link to={'/contact-us'} data-active={pathname === '/contact-us'} onClick={() => setMenuOpened(false)}>Contact us</Link>
                    </nav>
                </div>
            </div>
        </header>
    )
}
