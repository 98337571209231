import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home} from "./pages/home";
import {OurServices} from "./pages/our-services";
import {ContactUs} from "./pages/contact-us";
import {NotFound} from "./pages/not-found";
import './index.css'

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/our-services",
        element: <OurServices/>,
    },
    {
        path: "/contact-us",
        element: <ContactUs/>,
    },
    {
        path: "*",
        element: <NotFound/>,
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);