import {useNavigate} from "react-router-dom";
import {Page} from "../components/page";

export const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Page notFound>
            <div className="not-found">
                <div className='numbers'>404</div>
                <div className='text'>This page was not found</div>
                <button onClick={() => navigate('/')}>GO TO HOME PAGE</button>
            </div>
        </Page>
    )
}