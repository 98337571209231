import {useState} from "react";

export const Service = ({service: {title, shortText, fullText}}) => {
    const [serviceOpened, setServiceOpened] = useState(false);

    return(
        <div className='service'>
            {title}
            {serviceOpened ? (
                <>
                    {fullText}
                    <button className='white-stroke' onClick={() => setServiceOpened(false)}>Show less</button>
                </>
            ) : (
                <>
                    {shortText}
                    <button className='white-stroke' onClick={() => setServiceOpened(true)}>Show more</button>
                </>
            )}
        </div>
    )
}